import { Component } from 'react';
import { Modal as BModal } from 'bootstrap';
import { uuid } from '../uuid';
import { deleteJson } from '../api';
import { Submit } from './Form';

class Modal extends Component {
  uuid = uuid()
  modal = null
  componentDidMount = () => this.modal = new BModal(document.getElementById(this.uuid))
  componentDidUpdate = () => this.props.show ? this.modal.show() : this.modal.hide()
  render = () => <div
    id={this.uuid} className="modal fade" data-bs-backdrop="static"
  >
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-body">{this.props.children}</div>
      </div>
    </div>
  </div>
}

export class DeleteJobModals extends Component {
  state = { error: null, loading: 0, succeeded: false }
  async submit() {
    try {
      await deleteJson({ path: `job/${this.props.job.id}/` });
      this.setState({ succeeded: true });
    } catch {
      this.setState({ error: 'Failed to delete the job.' });
    }
  }
  resetState = () => this.setState({ error: null, succeeded: false }) && this.props.onCancel()
  render = () => <>
    <Modal show={this.state.error}>
      <h3>Job Deletion Error</h3>
      <p>{this.state.error}</p>
      <button className="btn btn-danger" onClick={() => this.resetState()}>
        OK
      </button>
    </Modal>
    <Modal show={this.state.succeeded}>
      <h3>Job Deletion Success</h3>
      <p>Job deleted successfully!</p>
      <button className="btn btn-danger" onClick={() => window.location.reload()}>
        OK
      </button>
    </Modal>
    <Modal show={this.props.job && !this.state.error && !this.state.succeeded}>
      <h3>Delete Job</h3>
      <p>
        Are you sure you want to delete&nbsp;
        <em><strong>{this.props.job?.filename}</strong></em> job?
      </p>
      <Submit
        label="Delete"
        onClick={() => this.submit()} loading={this.state.loading}
        setLoading={() => this.setState(previousState => ({ loading: previousState.loading + 1 }))}
        unsetLoading={() => this.setState(previousState => ({ loading: previousState.loading - 1 }))}
      />
      <button
        data-bs-dismiss="modal"
        className="btn btn-secondary mt-3 ms-2"
        disabled={this.state.loading > 0} onClick={() => this.resetState()}
      >Cancel</button>
    </Modal>
  </>;
}

export class JobStartupStatusModal extends Component {
  state = { finished: false, close: false }
  async componentDidUpdate(prevProps) {
    if (this.props.jobId && !prevProps.jobId) {
      this.setState({ close: true });
      await new Promise(resolve => setTimeout(resolve, 1));
      this.props.routerFeatures.navigate(`/v1.10/job/${this.props.jobId}`);
    }
  }
  render = () => <Modal
    show={this.props.submitted && !this.state.close}
  >
    <div className="my-4 mx-5">
      <h3>
        Starting Job...&nbsp;
        <span className="spinner-border ms-2" style={{ borderWidth: 5 }} />
      </h3>
    </div>
  </Modal>
}
