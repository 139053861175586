import { CaretDownIcon, CaretUpIcon, SearchIcon, TrashIcon } from './Icons';
import { Loadable } from './Loadable';
import { SelectViewMode } from './SelectViewMode';

import { getJson } from '../api';
import { Component } from 'react';
import { FlashMessage } from './FlashMessage';
import { withRouter } from '../navigation';
import { ModelStatusBadge, StageStatusBadge } from './StatusBadge';
import { DeleteJobModals } from './Modal';

const SearchBox = ({ placeholder, onValueChange }) => <div style={{ width: 650 }}>
  <div className="input-group">
    <span className="input-group-text pt-1 pb-0"><SearchIcon /></span>
    <input
      type="text"
      className="form-control"
      placeholder={placeholder}
      onChange={event => onValueChange(event.target.value)}
    />
  </div>
</div>;

const OrderableHeader = ({ ordering, label, onOrder }) => <th
  onClick={() => onOrder(label)} style={{ cursor: 'pointer' }}
>
  <span className="me-2">{label}</span>
  {ordering.key === label && (ordering.desc ? <CaretDownIcon /> : <CaretUpIcon />)}
</th>;

const JobHeader = ({ simplifiedView, ordering, onOrder }) => <tr>
  <OrderableHeader label="Filename" ordering={ordering} onOrder={onOrder} />
  <OrderableHeader label="Timestamp" ordering={ordering} onOrder={onOrder} />
  {!simplifiedView && <th>Enriched</th>}
  <th>Quick Model</th>
  <th>Complete Model</th>
  <th className="table-col-administration">Administration</th>
</tr>;

const JobRow = ({ job, simplifiedView, onJobDelete }) => <tr>
  <td title={job.id}>
    {
      process.env.REACT_APP_FRONTEND_REGULAR_URL ? <>
        <a href={`${process.env.REACT_APP_FRONTEND_REGULAR_URL}/#/v1.10/job/${job.id}`}>
          {job.filename}
        </a>
        <sup className="ms-2"><a href={`/#/v1.10/job/${job.id}`}>[admin]</a></sup>
      </> : <a href={`/#/v1.10/job/${job.id}`}>{job.filename}</a>
    }
  </td>
  <td>{job.timestamp}</td>
  {!simplifiedView && <td><StageStatusBadge stage={job.stages.enriched} /></td>}
  {
    simplifiedView ?
      <td><ModelStatusBadge model={job.models.quick} /></td> :
      <td>
        <StageStatusBadge stage={job.stages.quickmodel} prefix="model" />
        <span className="mx-1" />
        <StageStatusBadge stage={job.stages.quickoutput} prefix="output" />
      </td>
  }
  {
    simplifiedView ?
      <td><ModelStatusBadge model={job.models.complete} /></td> :
      <td>
        <StageStatusBadge stage={job.stages.completemodel} prefix="model" />
        <span className="mx-1" />
        <StageStatusBadge stage={job.stages.completeoutput} prefix="output" />
      </td>
  }
  <td className="table-col-administration">
    <button className="btn btn-outline-danger btn-sm" onClick={onJobDelete}><TrashIcon /></button>
  </td>
</tr>;

export class JobTable extends Component {
  jobs = [];
  state = {
    loading: 1, jobs: [], searchQuery: '',
    ordering: { key: 'Timestamp', desc: true }
  }
  defaultOrderingDesc = { 'Filename': false, 'Timestamp': true };
  async componentDidMount() {
    this.jobs = await getJson({ path: 'job/', asAdmin: true });
    this.setState({ jobsToShow: [...this.jobs], loading: 0 });
  }
  jobsToShow() {
    const orderingKey = this.state.ordering.key.toLowerCase();
    const orderingDirection = this.state.ordering.desc ? -1 : 1;
    const compare = (a, b) => (
      orderingDirection * a[orderingKey].localeCompare(b[orderingKey])
    );
    if (!this.state.searchQuery) {
      return [...this.jobs].sort(compare);
    }
    const searchQuery = this.state.searchQuery.toLowerCase();
    return this.jobs.filter(
      job => job.filename.toLowerCase().includes(searchQuery)
    ).sort(compare);
  }
  order(orderingKey) {
    this.setState(
      previousState => {
        if (previousState.ordering.key === orderingKey) {
          const desc = !previousState.ordering.desc;
          return { ordering: { key: orderingKey, desc } };
        }
        const desc = this.defaultOrderingDesc[orderingKey];
        return { ordering: { key: orderingKey, desc } };
      }
    );
  }
  render = () => <Loadable label="jobs" loading={this.state.loading}>
    <SearchBox
      placeholder="Search for Jobs"
      onValueChange={searchQuery => this.setState({ searchQuery })}
    />
    <table className="table table-sm table-scrollable">
      <thead>
        <JobHeader
          simplifiedView={this.props.viewMode === 'simplified'}
          ordering={this.state.ordering}
          onOrder={orderingKey => this.order(orderingKey)}
        />
      </thead>
      <tbody style={{ height: 550 }}>
        {
          this.jobsToShow().map(
            job => <JobRow
              key={job.id} job={job}
              simplifiedView={this.props.viewMode === 'simplified'}
              onJobDelete={() => this.props.onJobDelete(job)}
            />
          )
        }
      </tbody>
    </table>
  </Loadable>
}

class Index extends Component {
  state = { viewMode: 'detailed', jobToDelete: null };
  render = () => <>
    <DeleteJobModals
      job={this.state.jobToDelete}
      onCancel={() => this.setState({ jobToDelete: null })}
    />
    <div className="d-flex">
      <h3 className="flex-fill">List of Jobs</h3>
      <SelectViewMode
        value={this.state.viewMode}
        options={
          ['Simplified', 'Detailed'].map(
            label => ({ label, value: label.toLowerCase() })
          )
        }
        onChange={viewMode => this.setState({ viewMode })}
      />
    </div>
    <FlashMessage
      type="success"
      children={this.props.routerFeatures.location.state?.successMessage}
    />
    <JobTable
      viewMode={this.state.viewMode}
      onJobDelete={job => this.setState({ jobToDelete: job })}
    />
  </>
}

export const IndexWithRouter = withRouter(Index);
