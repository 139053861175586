import { createRoot } from 'react-dom/client';

import { HashRouter, Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { IndexWithRouter } from "./v1_10/components/Index";
import { NavbarItems as NAV_BAR_ITEMS_V1_1, SimDomainsRoutes as ROUTES_V1_1 } from './v1_1/routes';
import { NavbarItems as NAV_BAR_ITEMS_V1_2, SimDomainsRoutes as ROUTES_V1_2 } from './v1_2/routes';
import { NavbarItems as NAV_BAR_ITEMS_V1_3, SimDomainsRoutes as ROUTES_V1_3 } from './v1_3/routes';
import { NavbarItems as NAV_BAR_ITEMS_V1_4, SimDomainsRoutes as ROUTES_V1_4 } from './v1_4/routes';
import { NavbarItems as NAV_BAR_ITEMS_V1_5, SimDomainsRoutes as ROUTES_V1_5 } from './v1_5/routes';
import { NavbarItems as NAV_BAR_ITEMS_V1_6, SimDomainsRoutes as ROUTES_V1_6 } from './v1_6/routes';
import { NavbarItems as NAV_BAR_ITEMS_V1_7, SimDomainsRoutes as ROUTES_V1_7 } from './v1_7/routes';
import { NavbarItems as NAV_BAR_ITEMS_V1_8, SimDomainsRoutes as ROUTES_V1_8 } from './v1_8/routes';
import { NavbarItems as NAV_BAR_ITEMS_V1_9, SimDomainsRoutes as ROUTES_V1_9 } from './v1_9/routes';
import { NavbarItems as NAV_BAR_ITEMS_V1_10, SimDomainsRoutes as ROUTES_V1_10 } from './v1_10/routes';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init(
  {
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  }
);

const versions = {
  'v1.1': {
    comment: 'deprecated',
    navbarItems: NAV_BAR_ITEMS_V1_1,
    routes: ROUTES_V1_1,
  },
  'v1.2': {
    comment: 'deprecated',
    navbarItems: NAV_BAR_ITEMS_V1_2,
    routes: ROUTES_V1_2,
  },
  'v1.3': {
    comment: 'deprecated',
    navbarItems: NAV_BAR_ITEMS_V1_3,
    routes: ROUTES_V1_3,
  },
  'v1.4': {
    comment: 'deprecated',
    navbarItems: NAV_BAR_ITEMS_V1_4,
    routes: ROUTES_V1_4,
  },
  'v1.5': {
    comment: 'deprecated',
    navbarItems: NAV_BAR_ITEMS_V1_5,
    routes: ROUTES_V1_5,
  },
  'v1.6': {
    comment: 'deprecated',
    navbarItems: NAV_BAR_ITEMS_V1_6,
    routes: ROUTES_V1_6,
  },
  'v1.7': {
    comment: 'deprecated',
    navbarItems: NAV_BAR_ITEMS_V1_7,
    routes: ROUTES_V1_7,
  },
  'v1.8': {
    comment: 'stable',
    navbarItems: NAV_BAR_ITEMS_V1_8,
    routes: ROUTES_V1_8,
  },
  'v1.9': {
    comment: 'stable',
    navbarItems: NAV_BAR_ITEMS_V1_9,
    routes: ROUTES_V1_9,
  },
  'v1.10': {
    comment: 'current',
    navbarItems: NAV_BAR_ITEMS_V1_10,
    routes: ROUTES_V1_10,
  },
};

const SelectVersion = ({ value, onChange, disabled }) => <div
  className="form-floating" style={{ width: 200 }}
>
  {
    disabled ? <input
      type="text" className="form-control"
      value={`${value} (${versions[value].comment})`} disabled
    /> : <select
      id="version-select" className="form-select"
      onChange={event => onChange(event.target.value)}
      value={value}
    >
      {
        Object.entries(versions).map(
          ([number, { comment }],) => <option key={number} value={number}>
            {number} ({comment})
          </option>
        )
      }
    </select>
  }
  <label htmlFor="version-select">Release</label>
  <div className="d-flex">
    <div className="flex-fill" />
    {/* <a
      href={`/#/${value}/changelog`}
      className="bg-light bg-opacity-75 px-2 mt-1 rounded"
    >See Changelog</a>*/}
  </div>
</div>;

const Nav = ({ version, onVersionChange }) => <nav
  className="navbar navbar-light bg-light navbar-expand-lg fixed-top"
>
  <div className="container-fluid">
    {
      process.env.REACT_APP_DEPLOY_MODE === 'admin' ? <Link
        className="navbar-brand me-4" to={`/${version}/`}
      >
        <div className="d-flex">
          <img src="logo.png" alt="—" className="hidden-xs me-4" height="70" />
          <div style={{ height: 50 }}>
            <div style={{ marginTop: 9 }}>TAM Builder</div>
            <em
              className="ms-2 align-top position-relative"
              style={{ fontSize: 14, top: -10 }}
            >formerly <strong><ins>SimDomains</ins></strong></em>
          </div>
        </div>
      </Link> : <div
        className="navbar-brand"
        style={{ userSelect: 'none', cursor: 'default' }}
      >
        <div className="d-flex">
          <img src="logo.png" alt="—" className="hidden-xs me-4" height="70" />
          <div style={{ height: 50 }}>
            <div style={{ marginTop: 9 }}>TAM Builder</div>
            <em
              className="ms-2 align-top position-relative"
              style={{ fontSize: 14, top: -10 }}
            >formerly <strong><ins>SimDomains</ins></strong></em>
          </div>
        </div>
      </div>
    }
    <div
      className="navbar-collapse"
    >
      <div className="navbar-nav">
        {versions[version].navbarItems()}
      </div>
    </div>
    {
      <SelectVersion
        value={version} onChange={onVersionChange}
        disabled={useLocation().pathname.split('/')[2] === 'job'}
      />
    }
  </div>
</nav>;

const Main = ({ routes }) => <main className="container-fluid">
  <Routes>
    <Route path="/" element={<IndexWithRouter />} />
    {routes()}
  </Routes>
</main>;

function App() {
  const location = useLocation();
  const version = location.pathname.split('/')[1] || 'v1.10';
  const navigate = useNavigate();
  return <>
    <Nav
      version={version}
      onVersionChange={
        version => navigate(
          `/${version}/${location.pathname.split('/').splice(2).join('/')}`
        )
      }
    />
    <Main routes={versions[version].routes} />
  </>;
}

createRoot(document.getElementById('root')).render(
  <HashRouter><App /></HashRouter>
);
